import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Input, NgModule, Injectable } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2$1 from '@angular/router';
import { RouterModule } from '@angular/router';
import * as i1 from '@spartacus/core';
import { VariantQualifier, UrlModule, I18nModule, isNotUndefined, VariantType, isNotNullable, provideDefaultConfig } from '@spartacus/core';
import { filter, take, distinctUntilChanged, tap, switchMap, map } from 'rxjs/operators';
import * as i1$1 from '@spartacus/storefront';
import { of } from 'rxjs';

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
function ProductVariantColorSelectorComponent_option_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 4);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const v_r1 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵpropertyInterpolate("value", v_r1.code);
    i0.ɵɵproperty("selected", v_r1.code === (ctx_r1.product == null ? null : ctx_r1.product.code));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.getVariantOptionValue(v_r1.variantOptionQualifiers), " ");
  }
}
function ProductVariantSizeSelectorComponent_option_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const v_r1 = ctx.$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵpropertyInterpolate("value", v_r1.code);
    i0.ɵɵproperty("selected", v_r1.code === (ctx_r1.product == null ? null : ctx_r1.product.code));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.getVariantOptionValue(v_r1.variantOptionQualifiers), " ");
  }
}
const _c0 = a0 => ({
  "selected-variant": a0
});
function ProductVariantStyleSelectorComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "cxTranslate");
    i0.ɵɵelementStart(3, "span", 5);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(2, 2, "productVariants.style"), ": ");
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(ctx_r0.getVariantOptionValue(ctx_r0.variants == null ? null : ctx_r0.variants.selected.variantOptionQualifiers));
  }
}
function ProductVariantStyleSelectorComponent_li_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 6)(1, "button", 7);
    i0.ɵɵlistener("click", function ProductVariantStyleSelectorComponent_li_4_Template_button_click_1_listener() {
      const v_r3 = i0.ɵɵrestoreView(_r2).$implicit;
      const ctx_r0 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r0.changeStyle(v_r3.code));
    });
    i0.ɵɵelement(2, "img", 8);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const v_r3 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(4, _c0, v_r3.code === (ctx_r0.variants == null ? null : ctx_r0.variants.selected == null ? null : ctx_r0.variants.selected.code)));
    i0.ɵɵadvance(2);
    i0.ɵɵpropertyInterpolate("src", ctx_r0.getVariantThumbnailUrl(v_r3.variantOptionQualifiers), i0.ɵɵsanitizeUrl);
    i0.ɵɵpropertyInterpolate("title", ctx_r0.getVariantOptionValue(v_r3.variantOptionQualifiers));
    i0.ɵɵpropertyInterpolate("alt", ctx_r0.getVariantOptionValue(v_r3.variantOptionQualifiers));
  }
}
function ProductVariantsContainerComponent_ng_container_0_div_1_cx_product_variant_style_selector_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "cx-product-variant-style-selector", 5);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵproperty("variants", ctx_r0.variants[ctx_r0.variantType.STYLE]);
  }
}
function ProductVariantsContainerComponent_ng_container_0_div_1_cx_product_variant_size_selector_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "cx-product-variant-size-selector", 6);
  }
  if (rf & 2) {
    const product_r2 = i0.ɵɵnextContext(2).ngIf;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("product", product_r2)("variants", ctx_r0.variants[ctx_r0.variantType.SIZE]);
  }
}
function ProductVariantsContainerComponent_ng_container_0_div_1_cx_product_variant_color_selector_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "cx-product-variant-color-selector", 6);
  }
  if (rf & 2) {
    const product_r2 = i0.ɵɵnextContext(2).ngIf;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("product", product_r2)("variants", ctx_r0.variants[ctx_r0.variantType.COLOR]);
  }
}
function ProductVariantsContainerComponent_ng_container_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵtemplate(1, ProductVariantsContainerComponent_ng_container_0_div_1_cx_product_variant_style_selector_1_Template, 1, 1, "cx-product-variant-style-selector", 3)(2, ProductVariantsContainerComponent_ng_container_0_div_1_cx_product_variant_size_selector_2_Template, 1, 2, "cx-product-variant-size-selector", 4)(3, ProductVariantsContainerComponent_ng_container_0_div_1_cx_product_variant_color_selector_3_Template, 1, 2, "cx-product-variant-color-selector", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.variants[ctx_r0.variantType.STYLE]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.variants[ctx_r0.variantType.SIZE]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.variants[ctx_r0.variantType.COLOR]);
  }
}
function ProductVariantsContainerComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, ProductVariantsContainerComponent_ng_container_0_div_1_Template, 4, 3, "div", 1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const product_r2 = ctx.ngIf;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", product_r2.baseOptions == null ? null : product_r2.baseOptions.length);
  }
}
class ProductVariantColorSelectorComponent {
  constructor(routingService) {
    this.routingService = routingService;
  }
  changeColor(code, name) {
    if (code) {
      this.routingService.go({
        cxRoute: 'product',
        params: {
          code,
          name
        }
      });
    }
    return null;
  }
  getVariantOptionValue(qualifiers) {
    const obj = qualifiers.find(q => q.qualifier === VariantQualifier.COLOR);
    return obj ? obj.value : '';
  }
  static {
    this.ɵfac = function ProductVariantColorSelectorComponent_Factory(t) {
      return new (t || ProductVariantColorSelectorComponent)(i0.ɵɵdirectiveInject(i1.RoutingService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ProductVariantColorSelectorComponent,
      selectors: [["cx-product-variant-color-selector"]],
      inputs: {
        product: "product",
        variants: "variants"
      },
      decls: 7,
      vars: 4,
      consts: [[1, "variant-selector"], [1, "variant-name"], [1, "form-control", "variant-select", 3, "change"], [3, "value", "selected", 4, "ngFor", "ngForOf"], [3, "value", "selected"]],
      template: function ProductVariantColorSelectorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementContainerStart(0);
          i0.ɵɵelementStart(1, "div", 0)(2, "div", 1);
          i0.ɵɵtext(3);
          i0.ɵɵpipe(4, "cxTranslate");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "select", 2);
          i0.ɵɵlistener("change", function ProductVariantColorSelectorComponent_Template_select_change_5_listener($event) {
            return ctx.changeColor($event.target.value, ctx.product == null ? null : ctx.product.name);
          });
          i0.ɵɵtemplate(6, ProductVariantColorSelectorComponent_option_6_Template, 2, 3, "option", 3);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementContainerEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind1(4, 2, "productVariants.color"), ":");
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("ngForOf", ctx.variants == null ? null : ctx.variants.options);
        }
      },
      dependencies: [i2.NgForOf, i1.TranslatePipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProductVariantColorSelectorComponent, [{
    type: Component,
    args: [{
      selector: 'cx-product-variant-color-selector',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-container>\n  <div class=\"variant-selector\">\n    <div class=\"variant-name\">{{ 'productVariants.color' | cxTranslate }}:</div>\n\n    <select\n      (change)=\"changeColor($event.target.value, product?.name)\"\n      class=\"form-control variant-select\"\n    >\n      <option\n        *ngFor=\"let v of variants?.options\"\n        value=\"{{ v.code }}\"\n        [selected]=\"v.code === product?.code\"\n      >\n        {{ getVariantOptionValue(v.variantOptionQualifiers) }}\n      </option>\n    </select>\n  </div>\n</ng-container>\n"
    }]
  }], () => [{
    type: i1.RoutingService
  }], {
    product: [{
      type: Input
    }],
    variants: [{
      type: Input
    }]
  });
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class ProductVariantColorSelectorModule {
  static {
    this.ɵfac = function ProductVariantColorSelectorModule_Factory(t) {
      return new (t || ProductVariantColorSelectorModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ProductVariantColorSelectorModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, RouterModule, UrlModule, I18nModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProductVariantColorSelectorModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RouterModule, UrlModule, I18nModule],
      declarations: [ProductVariantColorSelectorComponent],
      exports: [ProductVariantColorSelectorComponent]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class ProductVariantSizeSelectorComponent {
  constructor(productService, routingService) {
    this.productService = productService;
    this.routingService = routingService;
  }
  changeSize(code) {
    if (code) {
      this.productService.get(code, "list" /* ProductScope.LIST */).pipe(
      // below call might looks redundant but in fact this data is going to be loaded anyways
      // we're just calling it earlier and storing
      filter(isNotUndefined), take(1)).subscribe(product => {
        this.routingService.go({
          cxRoute: 'product',
          params: product
        });
      });
    }
    return null;
  }
  getVariantOptionValue(qualifiers) {
    const obj = qualifiers.find(q => q.qualifier === VariantQualifier.SIZE);
    return obj ? obj.value : '';
  }
  static {
    this.ɵfac = function ProductVariantSizeSelectorComponent_Factory(t) {
      return new (t || ProductVariantSizeSelectorComponent)(i0.ɵɵdirectiveInject(i1.ProductService), i0.ɵɵdirectiveInject(i1.RoutingService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ProductVariantSizeSelectorComponent,
      selectors: [["cx-product-variant-size-selector"]],
      inputs: {
        product: "product",
        variants: "variants"
      },
      decls: 11,
      vars: 10,
      consts: [[1, "variant-selector"], [1, "variant-name"], [1, "form-control", "variant-select", 3, "change"], [3, "value", "selected", 4, "ngFor", "ngForOf"], ["href", "#", 1, "size-guide", 3, "title"], [3, "value", "selected"]],
      template: function ProductVariantSizeSelectorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementContainerStart(0);
          i0.ɵɵelementStart(1, "div", 0)(2, "div", 1);
          i0.ɵɵtext(3);
          i0.ɵɵpipe(4, "cxTranslate");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "select", 2);
          i0.ɵɵlistener("change", function ProductVariantSizeSelectorComponent_Template_select_change_5_listener($event) {
            return ctx.changeSize($event.target.value);
          });
          i0.ɵɵtemplate(6, ProductVariantSizeSelectorComponent_option_6_Template, 2, 3, "option", 3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "a", 4);
          i0.ɵɵpipe(8, "cxTranslate");
          i0.ɵɵtext(9);
          i0.ɵɵpipe(10, "cxTranslate");
          i0.ɵɵelementEnd()();
          i0.ɵɵelementContainerEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1("", i0.ɵɵpipeBind1(4, 4, "productVariants.size"), ":");
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("ngForOf", ctx.variants == null ? null : ctx.variants.options);
          i0.ɵɵadvance();
          i0.ɵɵpropertyInterpolate("title", i0.ɵɵpipeBind1(8, 6, "productVariants.sizeGuideLabel"));
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate1(" ", i0.ɵɵpipeBind1(10, 8, "productVariants.sizeGuideLabel"), " ");
        }
      },
      dependencies: [i2.NgForOf, i1.TranslatePipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProductVariantSizeSelectorComponent, [{
    type: Component,
    args: [{
      selector: 'cx-product-variant-size-selector',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-container>\n  <div class=\"variant-selector\">\n    <div class=\"variant-name\">{{ 'productVariants.size' | cxTranslate }}:</div>\n    <select\n      (change)=\"changeSize($event.target.value)\"\n      class=\"form-control variant-select\"\n    >\n      <option\n        *ngFor=\"let v of variants?.options\"\n        value=\"{{ v.code }}\"\n        [selected]=\"v.code === product?.code\"\n      >\n        {{ getVariantOptionValue(v.variantOptionQualifiers) }}\n      </option>\n    </select>\n    <a\n      href=\"#\"\n      class=\"size-guide\"\n      title=\"{{ 'productVariants.sizeGuideLabel' | cxTranslate }}\"\n    >\n      {{ 'productVariants.sizeGuideLabel' | cxTranslate }}\n    </a>\n  </div>\n</ng-container>\n"
    }]
  }], () => [{
    type: i1.ProductService
  }, {
    type: i1.RoutingService
  }], {
    product: [{
      type: Input
    }],
    variants: [{
      type: Input
    }]
  });
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class ProductVariantSizeSelectorModule {
  static {
    this.ɵfac = function ProductVariantSizeSelectorModule_Factory(t) {
      return new (t || ProductVariantSizeSelectorModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ProductVariantSizeSelectorModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, RouterModule, UrlModule, I18nModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProductVariantSizeSelectorModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RouterModule, UrlModule, I18nModule],
      declarations: [ProductVariantSizeSelectorComponent],
      exports: [ProductVariantSizeSelectorComponent]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class ProductVariantStyleSelectorComponent {
  constructor(config, productService, routingService) {
    this.config = config;
    this.productService = productService;
    this.routingService = routingService;
    this.variantQualifier = VariantQualifier;
  }
  getVariantOptionValue(qualifiers) {
    const obj = qualifiers.find(q => q.qualifier === VariantQualifier.STYLE);
    return obj ? obj.value : '';
  }
  getVariantThumbnailUrl(variantOptionQualifiers) {
    const qualifier = variantOptionQualifiers.find(item => item.image);
    return qualifier ? `${this.config?.backend?.occ?.baseUrl}${qualifier.image?.url}` : '';
  }
  changeStyle(code) {
    if (code) {
      this.productService.get(code, "list" /* ProductScope.LIST */).pipe(
      // below call might looks redundant but in fact this data is going to be loaded anyways
      // we're just calling it earlier and storing
      filter(isNotUndefined), take(1)).subscribe(product => {
        this.routingService.go({
          cxRoute: 'product',
          params: product
        });
      });
    }
    return null;
  }
  static {
    this.ɵfac = function ProductVariantStyleSelectorComponent_Factory(t) {
      return new (t || ProductVariantStyleSelectorComponent)(i0.ɵɵdirectiveInject(i1.OccConfig), i0.ɵɵdirectiveInject(i1.ProductService), i0.ɵɵdirectiveInject(i1.RoutingService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ProductVariantStyleSelectorComponent,
      selectors: [["cx-product-variant-style-selector"]],
      inputs: {
        variants: "variants"
      },
      decls: 5,
      vars: 2,
      consts: [[1, "variant-selector"], ["class", "variant-name", 4, "ngIf"], [1, "variant-list"], [3, "ngClass", 4, "ngFor", "ngForOf"], [1, "variant-name"], [1, "style-name"], [3, "ngClass"], [1, "variant-button", 3, "click"], [3, "src", "title", "alt"]],
      template: function ProductVariantStyleSelectorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementContainerStart(0);
          i0.ɵɵelementStart(1, "div", 0);
          i0.ɵɵtemplate(2, ProductVariantStyleSelectorComponent_div_2_Template, 5, 4, "div", 1);
          i0.ɵɵelementStart(3, "ul", 2);
          i0.ɵɵtemplate(4, ProductVariantStyleSelectorComponent_li_4_Template, 3, 6, "li", 3);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementContainerEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.variants.selected);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngForOf", ctx.variants == null ? null : ctx.variants.options);
        }
      },
      dependencies: [i2.NgClass, i2.NgForOf, i2.NgIf, i1.TranslatePipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProductVariantStyleSelectorComponent, [{
    type: Component,
    args: [{
      selector: 'cx-product-variant-style-selector',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-container>\n  <div class=\"variant-selector\">\n    <div *ngIf=\"variants.selected\" class=\"variant-name\">\n      {{ 'productVariants.style' | cxTranslate }}:\n      <span class=\"style-name\">{{\n        getVariantOptionValue(variants?.selected.variantOptionQualifiers)\n      }}</span>\n    </div>\n    <ul class=\"variant-list\">\n      <li\n        *ngFor=\"let v of variants?.options\"\n        [ngClass]=\"{ 'selected-variant': v.code === variants?.selected?.code }\"\n      >\n        <button class=\"variant-button\" (click)=\"changeStyle(v.code)\">\n          <img\n            src=\"{{ getVariantThumbnailUrl(v.variantOptionQualifiers) }}\"\n            title=\"{{ getVariantOptionValue(v.variantOptionQualifiers) }}\"\n            alt=\"{{ getVariantOptionValue(v.variantOptionQualifiers) }}\"\n          />\n        </button>\n      </li>\n    </ul>\n  </div>\n</ng-container>\n"
    }]
  }], () => [{
    type: i1.OccConfig
  }, {
    type: i1.ProductService
  }, {
    type: i1.RoutingService
  }], {
    variants: [{
      type: Input
    }]
  });
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class ProductVariantStyleSelectorModule {
  static {
    this.ɵfac = function ProductVariantStyleSelectorModule_Factory(t) {
      return new (t || ProductVariantStyleSelectorModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ProductVariantStyleSelectorModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, RouterModule, UrlModule, I18nModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProductVariantStyleSelectorModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RouterModule, UrlModule, I18nModule],
      declarations: [ProductVariantStyleSelectorComponent],
      exports: [ProductVariantStyleSelectorComponent]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class ProductVariantsContainerComponent {
  constructor(currentProductService) {
    this.currentProductService = currentProductService;
    this.variants = {};
    this.variantType = VariantType;
  }
  ngOnInit() {
    this.product$ = this.currentProductService.getProduct().pipe(filter(isNotNullable), filter(product => !!product.baseOptions), distinctUntilChanged(), tap(product => {
      product.baseOptions.forEach(option => {
        if (option?.variantType) {
          this.variants[option.variantType] = option;
        }
      });
    }));
  }
  static {
    this.ɵfac = function ProductVariantsContainerComponent_Factory(t) {
      return new (t || ProductVariantsContainerComponent)(i0.ɵɵdirectiveInject(i1$1.CurrentProductService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ProductVariantsContainerComponent,
      selectors: [["cx-product-variants-container"]],
      decls: 2,
      vars: 3,
      consts: [[4, "ngIf"], ["class", "variant-section", 4, "ngIf"], [1, "variant-section"], [3, "variants", 4, "ngIf"], [3, "product", "variants", 4, "ngIf"], [3, "variants"], [3, "product", "variants"]],
      template: function ProductVariantsContainerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, ProductVariantsContainerComponent_ng_container_0_Template, 2, 1, "ng-container", 0);
          i0.ɵɵpipe(1, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 1, ctx.product$));
        }
      },
      dependencies: [i2.NgIf, ProductVariantStyleSelectorComponent, ProductVariantSizeSelectorComponent, ProductVariantColorSelectorComponent, i2.AsyncPipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProductVariantsContainerComponent, [{
    type: Component,
    args: [{
      selector: 'cx-product-variants-container',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-container *ngIf=\"product$ | async as product\">\n  <div class=\"variant-section\" *ngIf=\"product.baseOptions?.length\">\n    <cx-product-variant-style-selector\n      *ngIf=\"variants[variantType.STYLE]\"\n      [variants]=\"variants[variantType.STYLE]\"\n    ></cx-product-variant-style-selector>\n    <cx-product-variant-size-selector\n      *ngIf=\"variants[variantType.SIZE]\"\n      [product]=\"product\"\n      [variants]=\"variants[variantType.SIZE]\"\n    ></cx-product-variant-size-selector>\n    <cx-product-variant-color-selector\n      *ngIf=\"variants[variantType.COLOR]\"\n      [product]=\"product\"\n      [variants]=\"variants[variantType.COLOR]\"\n    ></cx-product-variant-color-selector>\n  </div>\n</ng-container>\n"
    }]
  }], () => [{
    type: i1$1.CurrentProductService
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class ProductVariantsContainerModule {
  static {
    this.ɵfac = function ProductVariantsContainerModule_Factory(t) {
      return new (t || ProductVariantsContainerModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ProductVariantsContainerModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, RouterModule, UrlModule, I18nModule, ProductVariantStyleSelectorModule, ProductVariantSizeSelectorModule, ProductVariantColorSelectorModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProductVariantsContainerModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RouterModule, UrlModule, I18nModule, ProductVariantStyleSelectorModule, ProductVariantSizeSelectorModule, ProductVariantColorSelectorModule],
      declarations: [ProductVariantsContainerComponent]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
/**
 * Guard that will redirect to purchasable variant of product, if the navigation
 * is for the non-purchasable one
 */
class ProductVariantsGuard {
  constructor(productService, semanticPathService, router) {
    this.productService = productService;
    this.semanticPathService = semanticPathService;
    this.router = router;
  }
  canActivate(activatedRoute) {
    const productCode = activatedRoute.params?.productCode;
    if (!productCode) {
      return of(true);
    }
    return this.productService.get(productCode, "variants" /* ProductScope.VARIANTS */).pipe(filter(isNotUndefined), switchMap(product => {
      if (!product.purchasable) {
        const purchasableCode = this.findPurchasableProductCode(product);
        if (purchasableCode) {
          return this.productService.get(purchasableCode, "list" /* ProductScope.LIST */).pipe(filter(isNotUndefined), take(1), map(_product => {
            return this.router.createUrlTree(this.semanticPathService.transform({
              cxRoute: 'product',
              params: _product
            }));
          }));
        }
      }
      return of(true);
    }));
  }
  /**
   * Finds a purchasable product code looking at variant options, if any
   *
   * @param product
   */
  findPurchasableProductCode(product) {
    if (product.variantOptions?.length) {
      const results = product.variantOptions.filter(variant => {
        return variant.stock && variant.stock.stockLevel ? variant : false;
      });
      return results && results.length ? results[0]?.code : product.variantOptions[0]?.code;
    }
    return undefined;
  }
  static {
    this.ɵfac = function ProductVariantsGuard_Factory(t) {
      return new (t || ProductVariantsGuard)(i0.ɵɵinject(i1.ProductService), i0.ɵɵinject(i1.SemanticPathService), i0.ɵɵinject(i2$1.Router));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ProductVariantsGuard,
      factory: ProductVariantsGuard.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProductVariantsGuard, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.ProductService
  }, {
    type: i1.SemanticPathService
  }, {
    type: i2$1.Router
  }], null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
class ProductVariantsComponentsModule {
  static {
    this.ɵfac = function ProductVariantsComponentsModule_Factory(t) {
      return new (t || ProductVariantsComponentsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ProductVariantsComponentsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [provideDefaultConfig({
        cmsComponents: {
          ProductVariantSelectorComponent: {
            component: ProductVariantsContainerComponent,
            guards: [ProductVariantsGuard]
          }
        }
      })],
      imports: [ProductVariantsContainerModule, ProductVariantColorSelectorModule, ProductVariantSizeSelectorModule, ProductVariantStyleSelectorModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProductVariantsComponentsModule, [{
    type: NgModule,
    args: [{
      imports: [ProductVariantsContainerModule, ProductVariantColorSelectorModule, ProductVariantSizeSelectorModule, ProductVariantStyleSelectorModule],
      providers: [provideDefaultConfig({
        cmsComponents: {
          ProductVariantSelectorComponent: {
            component: ProductVariantsContainerComponent,
            guards: [ProductVariantsGuard]
          }
        }
      })]
    }]
  }], null, null);
})();

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * SPDX-FileCopyrightText: 2024 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ProductVariantColorSelectorComponent, ProductVariantColorSelectorModule, ProductVariantSizeSelectorComponent, ProductVariantSizeSelectorModule, ProductVariantStyleSelectorComponent, ProductVariantStyleSelectorModule, ProductVariantsComponentsModule, ProductVariantsContainerComponent, ProductVariantsContainerModule, ProductVariantsGuard };
